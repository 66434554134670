import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '../stores/tenantAuthstore'


const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'landingpage',
      component: () => import("../views/tenant/landingpage/Landingpage.vue")
    },
    {
      path: '/auth/tenant/:tenant/openid/getToken/:code/:state',
      name: 'keycloakGetToken',
      component: () => import("../views/tenant/openidGetToken/OpenidGetToken.vue")
    },
    {
      path: '/app',
      name: 'app',
      component: () => import("../views/tenant/apphome/Apphome.vue"),
      children: [
        { path: 'iv', name: 'info_view', component: () => import("../views/tenant/apphome/info_view/InfoView.vue") },
        { path: 'sc', name: 'search_customer', component: () => import("../views/tenant/apphome/search_customer/SearchCustomer.vue") },
        { path: 'cc', name: 'check_customer', component: () => import("../views/tenant/apphome/check_customer/CheckCustomer.vue") },
        { path: 'jfu', name: 'jira_find_update', component: () => import("../views/tenant/apphome/jira_find_update/JiraFindUpdate.vue") },
        { path: 'afu', name: 'adb_find_update', component: () => import("../views/tenant/apphome/adb_find_update/ADBFindUpdate.vue") },
        { path: 'qsd', name: 'query_session_data', component: () => import("../views/tenant/apphome/query_session_data/QuerySessiondata.vue") },
        { path: 'maf', name: 'maintenance_faults', component: () => import("../views/tenant/apphome/maintenance_faults/MaintenanceFaults.vue") },
      ]
    },
    { path: '/:pathMatch(.*)*', component: () => import("../views/common/error/ErrorPage.vue") }
  ]
})

router.beforeEach(async (to, from) => {
  // Allow access to shared files
  // const regex = new RegExp('.*(secret|file)/(public|private).*')
  // if (regex.test(to.path)) {
  //   return true
  // }
  // Drop all back to index when path not allowed here
  const publicPages = /^\/$|^\/auth\/.*/g;
  const authRequired = !publicPages.test(to.path);
  const auth = useAuthStore()

  if (authRequired && !auth.signature) {
    auth.doLogout()
    return "/"
  }
})


export default router
