import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '../stores/baseAuthstore'


const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: '/',
      name: 'landingpage',
      component: () => import("../views/base/landingpage/Landingpage.vue")
    },
    {
      path: '/auth/openid/getToken/:code/:state',
      name: 'keycloakGetToken',
      component: () => import("../views/base/openidGetToken/OpenidGetToken.vue")
    },
    {
      path: '/app',
      name: 'app',
      component: () => import("../views/base/apphome/Apphome.vue"),
      children: [
        {
          path: 'tenants',
          name: 'list.tenants',
          component: () => import("../views/base/tenant/ListTenants.vue"),
        },
        {
          path: 'tenant',
          name: 'new.tenant',
          component: () => import("../views/base/tenant/NewTenant.vue"),
        },
        {
          path: 'tenant/:id',
          name: 'edit.tenant',
          component: () => import("../views/base/tenant/EditTenant.vue"),
        }
      ]
    },
    { path: '/:pathMatch(.*)*', component: () => import("../views/common/error/ErrorPage.vue") }
  ]
})

router.beforeEach(async (to, from) => {
  // Allow access to shared files
  // const regex = new RegExp('.*(secret|file)/(public|private).*')
  // if (regex.test(to.path)) {
  //   return true
  // }
  // Drop all back to index when path not allowed here
  const publicPages = /^\/$|^\/auth\/.*/g;
  const authRequired = !publicPages.test(to.path);
  const auth = useAuthStore()

  if (authRequired && !auth.signature) {
    auth.doLogout()
    return '/'
  }
})


export default router
