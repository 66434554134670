import { createApp } from 'vue'
import { createPinia } from 'pinia'
// import * as Sentry from "@sentry/vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue'
import routerBase from './router/routerBase'
import routerTenant from './router/routerTenant'
import Vue3NativeNotification from 'vue3-native-notification'

import './assets/app.css'

const app = createApp(App)

app.use(createPinia())

const host = window.location.host;
const viteRootDomain = import.meta.env.VITE_ROOT_DOMAIN
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const SAMPLE_RATE_ENV = import.meta.env.VITE_RELEASE_ENV

var router = null
if (host === viteRootDomain) {
  router = routerBase
} else {
  router = routerTenant
}
app.use(router)
app.use(Vue3NativeNotification, { requestOnNotify: true })

const toastOptions = {}
app.use(Toast, toastOptions);

// Sentry.init({
//   app,
//   dsn: SENTRY_DSN,
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: [viteRootDomain, /^\//],
//     }),
//     new Sentry.Replay(),
//   ],
//   // track components lifecycle
//   trackComponents: true,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: SAMPLE_RATE_ENV === 'development' ? 1.0 : 0.25,
//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: SAMPLE_RATE_ENV === 'development' ? 1.0 : 0.25,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,
//   // release name from vite env
//   release: import.meta.env.VITE_RELEASE_NAME,
//   environment: import.meta.env.VITE_RELEASE_ENV,
// });

console.log(`%c,
.....................:~~~~~~~^:..............................................
...................:~Y7!!!!!77??7~:..........................................
...................:~5!::::::::^~7??!:.......................................
.....................^?J~::::::::::^7J?^.....................................
......................:~JJ^:^:::::::::!J?^...................................
........................:!Y?::^^::::::::!Y7:.::::............................
.......STOP...............:?Y~:^^:::::::::?Y????YY?~:........................
....ZAPPI-TIME..............~5!:^^::::::::.~5!^?Y?YG~:.......................
.............................^57:^^:::::::::^57Y?!!5P^:......................
..............................^5!:^^:::::::::^Y5J!!?B!:......................
.......................::^~~~~~?5^^^^::::::::.^G5!!7G7:......................
....................^!J5PP5YJ?775?^^^^^~7777?775PJ?YB!:......................
..................^JPP5J????JYY?75^^7YYJJ?!^~~^^~^^~!Y7:.....................
................:~GP7!!!!!!!7JY55P~?G5J?YJ!7::~^:~::.:Y?:....................
...............:^PP!!!!!!!JY?~^:7PJG77?!!JY!!::~:^~::::5!:...................
...............:~BJ!!~!?!5?^...::5BY~7?!!!YJ?^:^~:~:::^!5::..................
...............:~B?~!~!GP!.....:^GB!~?7!!!7P7!:^~:~:::!:P~:..................
...............:^GJ~~?!P?:.....:JGY~!J!!!!!P77::~:::::~:5~:..................
................:JP~~!75^:....:!P7!~?7~!!!!5?7^:~^:::^~^P^:..................
................:^G?~~Y?:....:~P7~~7?~!!!!!?5!!.~^:::~:Y7:...................
.................:!P??P~:...:^57^~!J!~!!!!!7P!7.^^::~:JJ::...................
..................:!77!:....^5?^~7?!~!!!!!7YG!7::::^~J?:.....................
...........................~57^~??!~!!!!!JY7P!7.:.^7Y!:......................
.........................:!57~7?7~!!~~!?Y?^^P7?!!7J7:........................
........................:!P?7?7!~~~!7JY?^..:~77?J!:..........................
.......................~J5J?7!!!77JJ?!:........:.............................
....................:^?5Y?7?JJJ??7~:.........................................
....................:?P?7?YJ!::..............................................
.....................:?5YJ~:.................................................
......................:^^....................................................
`, `font-family: monospace`);



app.mount('#app')