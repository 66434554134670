import axios from 'axios';
import { ref } from 'vue';
import { defineStore } from 'pinia'
import router from '../router/routerBase';

export const useAuthStore = defineStore('authStore', () => {

  const claims = ref(JSON.parse(localStorage.getItem("claims"))) || null
  const signature = ref(localStorage.getItem("signature")) || null

  async function doLogin(payload) {
    delete axios.defaults.headers.common['Authorization']
    try {
      let response = await axios({
        url: payload.url,
        data: payload.data,
        method: 'POST'
      })
      if (response.status === 200) {
        axios.defaults.headers.common["Authorization"] = response?.data?.Signature;
        claims.value = response?.data?.Claims
        signature.value = response?.data?.Signature
        localStorage.setItem("claims", JSON.stringify(response?.data?.Claims))
        localStorage.setItem("signature", response?.data?.Signature)
      }
      return response
    } catch (error) {
      claims.value = ""
      signature.value = ""
      localStorage.removeItem("claims")
      localStorage.removeItem("signature")
      return error?.response
    }
  }

  function doLogout() {
    delete axios.defaults.headers.common['Authorization']
    claims.value = null
    signature.value = null
    localStorage.removeItem("claims")
    localStorage.removeItem("signature")
    router.push("/")
  }

  function doLogoutError() {
    delete axios.defaults.headers.common['Authorization']
    claims.value = null
    signature.value = null
    localStorage.removeItem("claims")
    localStorage.removeItem("signature")
    router.push("/")
  }

  return { claims, signature, doLogin, doLogout, doLogoutError }
})